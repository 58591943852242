import { Injectable } from '@angular/core';
import { IFCMService } from './ifcm.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { DeviceService, UserDevice } from '../push-notifications/device.service';
import { User, UserFirestore } from '../content/user.service';
import { from } from 'rxjs';
import * as uuidv5 from 'uuid/v5';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class FCMMobileService implements IFCMService {

    token: string;

    constructor(private fcm: FCM,
                private deviceService: DeviceService,
                private deviceDetector: DeviceDetectorService) {

    }
    getToken() {
        // Token
        return from(this.fcm.getToken());
    }
    onTokenRefresh() {
        // Refresh
        return this.fcm.onTokenRefresh();
    }
    onNotification() {
        // Whenn the notification happens
        return this.fcm.onNotification();
    }

    subscribeToTopic(topic: string) {
        return this.fcm.subscribeToTopic(topic);
    }

    unSubcribeToTopic(topic: string) {
        return this.fcm.unsubscribeFromTopic(topic);
    }

    sendTokenToServer(email: string, currentToken: string) {
        const infoDevice = this.deviceDetector.getDeviceInfo();
        const uuid = email + '_' + infoDevice.device + '_' + infoDevice.os;
        console.log(uuid);
        const newDevice: UserDevice = {
            id: uuidv5(uuid, uuidv5.DNS),
            uuid: currentToken,
            created: new Date().toUTCString(),
            last_access: new Date().toUTCString(),
            info: infoDevice,
            user: email
        };
        return this.deviceService.addDevice(email, newDevice);
    }

    removeTokenFromServer(email: string, currentToken: string) {
        const infoDevice = this.deviceDetector.getDeviceInfo();
        const uuid = email + '_' + infoDevice.browser + '_' + infoDevice.os;
        console.log('Device uuid : ', uuidv5(uuid, uuidv5.DNS));
        return this.deviceService.removeDevice(uuidv5(uuid, uuidv5.DNS));
    }

}

