import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, IonReorderGroup, AlertController } from '@ionic/angular';
import { UserFirestore, UserService } from '../../services/content/user.service';
import { Observable, from } from 'rxjs';
import * as _ from 'lodash';
import { TopicService } from '../../services/content/topic.service';
import { CONSTANTS } from '../../services/constants';
import { FCMService } from '../../services/push-notifications/fcm.service';

@Component({
  selector: 'app-feedsettings',
  templateUrl: './feedsettings.page.html',
  styleUrls: ['./feedsettings.page.scss'],
})
export class FeedsettingsPage implements OnInit {
  @ViewChild(IonReorderGroup, { static: false }) reorderGroup: IonReorderGroup;

  constructor(private modalController: ModalController,
              private userService: UserService,
              private topicService: TopicService,
              private alertController: AlertController,
              private fcmService: FCMService) {

  }
  localTopics: any[];
  @Input() public topics: any[];
  @Input() public originalTopics: any[];
  @Input() public user: any;

  reorderDisabled = false;
  showReset = false;
  topicsChanged = false;
  ngOnInit() {

    if (this.user.preferences.pwa_user_preference_topics !== CONSTANTS.NOTOPICS) {
      this.showReset = true;
    }

    this.localTopics = _.cloneDeep(this.topics);
    this.localTopics = this.localTopics.filter(item => item.id !== CONSTANTS.ALLTOPICID);


    this.topicService.prepareTopicsListByUser(this.localTopics, this.user.preferences);

  }

  async closeModal() {
    if (this.topicsChanged) {
      const topicsToSave = [];
      this.localTopics.forEach(topic => {
        if (topic.id !== CONSTANTS.ALLTOPICID
          && topic.checked) {
          topicsToSave.push(topic.id);
        }
      });
      this.userService.updateUserTopicsPrefences(topicsToSave);
      this.fcmService.subscribeToTopic(this.fcmService.specifiedByUser).then(() => {
        const promisesToExecUnsub = [];
        this.user.orgs.forEach(org => {
          promisesToExecUnsub.push(this.fcmService.unSubcribeToTopic(org.id));
        });
        return Promise.all(promisesToExecUnsub);
      });


    }
    this.localTopics.unshift(CONSTANTS.all_topic);
    this.topics = this.localTopics;
    await this.modalController.dismiss(this.topics);
  }


  doReorder(ev: any) {
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    this.topics = ev.detail.complete(this.localTopics);
    this.topicsChanged = true;

  }

  toggleReorderGroup() {
    this.reorderGroup.disabled = !this.reorderGroup.disabled;
  }

  changeTopic(event) {
    this.localTopics.forEach(topic => {
      if (topic.id === event.detail.value) {
        topic.checked = event.detail.checked;
      }
    });
    this.topicsChanged = true;
  }

  async resetSettings() {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'Are you sure you want to reset?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Reset',
          handler: async () => {
            const topicsToSave = [];
            this.userService.updateUserTopicsPrefences(topicsToSave);
            this.topics = _.cloneDeep(this.originalTopics);
            this.topicService.prepareTopicsListByUser(this.topics, {
              pwa_user_preference_topics : CONSTANTS.NOTOPICS
            });
            this.fcmService.unSubcribeToTopic(this.fcmService.specifiedByUser).then(() => {
              const promisesToExecSub = [];
              this.user.orgs.forEach(org => {
                promisesToExecSub.push(this.fcmService.subscribeToTopic(org.id));
              });
              return Promise.all(promisesToExecSub);
            });

            await this.modalController.dismiss(this.topics);
          }
        }
      ]
    });

    await alert.present();
  }

}
