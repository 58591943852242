import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference
} from "@angular/fire/firestore";
import { map, first } from "rxjs/operators";
import { Observable } from "rxjs";
import { UserFirestore} from "./user.service";
import { OrgFirestore} from "./org.service";
import { TopicService } from "./topic.service";
export interface Post {
  id: string;
  img: string;
  topics: string[];
  title: string;
  date: string;
  excerpt: string;
  info: string;
  bookmarked: boolean;
  seen: boolean;
  body: string;
}
export interface PostFirestore {
  id?: string;
  post_id: string;
  post_content: string;
  post_date: string;
  post_date_gmt: string;
  post_excerpt: string;
  post_feature_image: string;
  post_name: string;
  post_status: string;
  post_title: string;
  post_topics: DocumentReference[];
  topics_display?: string[];
  post_type: string;
  info?: string;
  bookmarked?: boolean;
  seen?: boolean;
  post_visible?: boolean;
}
@Injectable({
  providedIn: "root"
})
export class PostService {
  public posts: PostFirestore[];
  public topics = [];
  public all_topic = {id: "all", topic_description: "", topic_name: "All", topic_sync_date: "", topic_uuid: ""};
  public user_prefs: UserFirestore;
  public afsOrgPostsArray: Observable<DocumentReference[]>;
  private afsPostCollection: AngularFirestoreCollection<PostFirestore>;
  constructor(
    private afs: AngularFirestore,
    private topicService: TopicService
  ) {}
  mapPostTopicsforMatch(post_topics: DocumentReference[], active_topic) {
    return Promise.all(
      post_topics.map(t => {
        if (t.id == active_topic) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
  mapPostTopicsforDisplay(post_topics: DocumentReference[]) {
    return post_topics.map(t => {
      const topicName = t.id;
      return topicName.replace(/marketplace/g, " ").replace(/otsuka/g, " ").replace(/-/g, " ").toUpperCase();
    });
  }
  checkifPostseen(user_prefs: any, post_id: string) {
    var found = user_prefs.user_history.marketplace_post_viewer.find(
      history => history.id === post_id
    );
    if (found) {
      return true;
    }
    return false;
  }
  checkifPostBookmarked(user_prefs: any, post_id: string) {
    var found = user_prefs.user_pinned.marketplace_post_viewer.find(
      pinned => pinned.id === post_id
    );
    if (found) {
      return true;
    }
    return false;
  }
  checkifPostInfo(post_id: string) {
    return "";
  }
  /**
   *
   * Get a post by Id
   */
  getPost(
    id: string,
    orgsRef,
    history, bookmarks
  ): Observable<PostFirestore> {
    // console.log("getPost() ", id);
    // const org_id = user.user_org[0].id;
    this.afsPostCollection = this.afs.collection<PostFirestore>(
      "post",
      ref => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        query = query
          .where("post_orgs", "array-contains-any", orgsRef)
          .limit(10);
        return query;
      }
    );
    return this.afsPostCollection
      .doc<PostFirestore>(id)
      .valueChanges()
      .pipe(
        map(post => {
          post.id = id;
          post.topics_display = this.mapPostTopicsforDisplay(post.post_topics);
          if(!post.post_feature_image){
            post.post_feature_image = "https://fenix.group/assets/fenix-analysis.png";
          }
          post.bookmarked = this.checkifPostExists(bookmarks, id);
          post.seen = this.checkifPostExists(history, id);
          return post;
        })
      );
  }
  getTopics() {
    return this.topics;
  }
  getPostsData(postsSnapshotAction, _show_bookmarked=false, history, bookmarks) {
    this.posts = postsSnapshotAction
      .map(a => {
        const data = a.payload.doc.data() as PostFirestore;
        const id = a.payload.doc.id;
        data.topics_display = this.mapPostTopicsforDisplay(data.post_topics);
        if(!data.post_feature_image){
          data.post_feature_image = "https://fenix.group/assets/fenix-analysis.png";
        }
        data.bookmarked = this.checkifPostExists(bookmarks, id);
        data.seen = this.checkifPostExists(history, id);
        return { id, ...data };
      })
      .filter(post => {
        // console.log("FILTER", post);
        if (_show_bookmarked) {
          if (post.bookmarked) {
            return post;
          } else {
            return false;
          }
        }
        return post;
      });
    return this.posts;
  }
  async getBookmarkedPosts(postsRef: DocumentReference[], history): Promise<any> {

    return Promise.all(postsRef.map(post => {
      return post.get().then((t) => {
        if ( t.exists ) {
          const id = t.id;
          const post_data = t.data();
          post_data.bookmarked = true;
          post_data.seen = this.checkifPostExists(history, id);
          // console.log("getUserTopics org_topic_data", topic_data);
          return { id, ...post_data };
        }
  		});
    }));
    
  }


  checkifPostExists(prefArray: any, id:string){
    if(prefArray){
      var found = prefArray.find(
        post => post.id === id
      );
      if (found) {
        return true;
      }
    }
    return false;
  }
  async getPostsCollection(active_topic_id, orgsRef, history, bookmarks): Promise<any> {
    // console.log("orgsREF", orgsRef);
    this.afsPostCollection = this.afs.collection<PostFirestore>(
      "post",
      ref => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
          if (active_topic_id !== "all") {
              const topicCollection = this.afs.collection('topic');
              const topicRef = topicCollection.doc(`${active_topic_id}`).ref;
              query = query
                .where("post_topics", "array-contains", topicRef)
                .orderBy("post_date", "desc");
            }else{
              query = query
                    .where("post_orgs", "array-contains-any", orgsRef)
                    .orderBy("post_date", "desc"); //.limit(10)
            }
          return query;
        }
      );
      return this.afsPostCollection.snapshotChanges();
  }

  getPostByPostName(postName: string) {
    console.log(postName);
    const afsPostCo = this.afs.collection<PostFirestore>(
      'post',
      ref => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        query = query
          .where('post_name', '==', postName)
          .limit(1);
        return query;
      }
    );
    return afsPostCo.snapshotChanges().pipe(first(), map(currentPost => {
      const data = currentPost[0].payload.doc.data();
      data.id = currentPost[0].payload.doc.id;
      return data;
    }));
  }

}