import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './services/auth.service';
import { FeedsettingsPage } from "./modals/feedsettings/feedsettings.page";


// Firebase Libs
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireRemoteConfigModule, SETTINGS} from '@angular/fire/remote-config';
import { environment } from '../environments/environment';
import { DeviceDetectorModule } from 'ngx-device-detector';

// Notification Libs
import { FCM } from '@ionic-native/fcm/ngx';
import { Badge } from '@ionic-native/badge/ngx';

// Algolia search
import { NgAisModule, NgAisInstantSearch } from 'angular-instantsearch';

// DeepLinks
import { FenixDeepLinks } from './services/content/deeplinks.service';

@NgModule({
  declarations: [AppComponent,FeedsettingsPage],
  entryComponents: [FeedsettingsPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'pwa-ionic-angular'), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFirestoreModule.enablePersistence(),
    NgAisModule.forRoot(), // Algolia,
    DeviceDetectorModule.forRoot(),
    AngularFireRemoteConfigModule //Remote Config
  ],
  providers: [
    AuthService,
    SafariViewController,
    StatusBar,
    SplashScreen,
    InAppBrowser,
    FCM,
    Badge,
    FenixDeepLinks,
    AngularFireMessaging,
    AngularFireFunctions,
    NgAisInstantSearch,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: SETTINGS,
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 1 } : { minimumFetchIntervalMillis: 1 }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
