// Change this filename to auth.config.ts

export const AUTH_CONFIG = {
  // Needed for Auth0 (capitalization: ID):
  clientID: 'oT6JeDHzXZrY6D6hifXSodvWszO1blIM',
  // Needed for Auth0Cordova (capitalization: Id):
  clientId: 'oT6JeDHzXZrY6D6hifXSodvWszO1blIM',
  client_id: 'oT6JeDHzXZrY6D6hifXSodvWszO1blIM',
  domain: 'auth0.fenix.group', // e.g., you.auth0.com
  packageIdentifier: 'io.fenix.post.viewer', // config.xml widget ID
  appRedirect: 'https://app.fenix.group/',
  redirectUri: 'https://app.fenix.group',
  audience: 'https://slash-go.eu.auth0.com/userinfo',
  responseType: 'token id_token',
  scope: 'openid profile email'
  //PWA Redirection
  //appRedirect: 'https://fenix-feed.firebaseapp.com/'
  //PWA.fenix.group
  //appRedirect: 'https://pwa.fenix.group/'

};
