import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../constants';

export interface Topic {
  id: string,
  title: string,
  description: string
}

export interface TopicFirestore {
  topic_uuid: string,
  topic_name: string,
  topic_description: string,
}


@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor() {}


  mappingFromFirestoreToPwaTopic(data: TopicFirestore)  {

    const dataTransformed: Topic = {
      id: data.topic_uuid,
      title: data.topic_name,
      description: data.topic_description,
    };

    return dataTransformed;

  }

  async getUserTopics(topicsRef: DocumentReference[]): Promise<any> {

    return Promise.all(topicsRef.map(topic => {
      return topic.get().then((t) => {
  			const id = t.id;
  			const topic_data = t.data();
        // console.log("getUserTopics org_topic_data", topic_data);
        return { id, ...topic_data };
        
  		});
    }));
    
  }

  async getTopicData(topicsRef: DocumentReference[]): Promise<any> {

    return Promise.all(topicsRef.map(topic => {
      return topic.get().then((t) => {
  			const id = t.id;
  			const topic_data = t.data();
        // console.log("getUserTopics org_topic_data", topic_data);
        return { id, ...topic_data };
        
  		});
    }));
    
  }

  prepareTopicsListByUser(localTopics: any[], user_preference: any) {

    //Setting states for each topic 
    localTopics.forEach(topic => {

      if (user_preference.pwa_user_preference_topics === CONSTANTS.NOTOPICS) {
        topic.checked = true;
        topic.enabled = true;
      } else {
        topic.checked = false;
        topic.enabled = true;
        user_preference.pwa_user_preference_topics.forEach(userTopic => {
          if (topic.id === userTopic.id) {
            topic.checked = true;
            topic.enabled = true;
          }
        });
      }
      if (topic.id === CONSTANTS.ALLTOPICID) {
        topic.checked = true;
        topic.enabled = false;
      }
    });

    //Ordering topics by user preferences
    let topicsOrdered = {
      "all": 1
    };
    if (user_preference.pwa_user_preference_topics !== CONSTANTS.NOTOPICS) {
      user_preference.pwa_user_preference_topics.forEach((t, index) => {
        console.log(t.id);
        topicsOrdered[t.id] = index + 1;
      });
      console.log(topicsOrdered);
      localTopics.sort(function (a, b) {
        // console.log(a.id, b.id);
        // console.log(topicsOrdered[a.id], topicsOrdered[b.id]);
        const valueA = topicsOrdered[a.id] ? topicsOrdered[a.id] : 999;
        const valueB = topicsOrdered[b.id] ? topicsOrdered[b.id] : 999;
        // console.log({ valueA }, { valueB });
        return valueA - valueB;
      });
    }

    return localTopics;
  }

}
