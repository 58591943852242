// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC39ALJ0SUEkK5XvZYUO-d1awrh-H_wY2M",
    authDomain: "fenix-marketplace-data.firebaseapp.com",
    databaseURL: "https://fenix-marketplace-data.firebaseio.com",
    projectId: "fenix-marketplace-data",
    storageBucket: "fenix-marketplace-data.appspot.com",
    messagingSenderId: "688897010213",
    appId: "1:688897010213:web:2c61b969d1a96852e3edcc",
    measurementId: "G-FYBD2C7E8P",
    vapidKey: 'BOkbCIPlboazfIEhEKAAOB1FT3L89RPJK2YJ4HI7EMnTaVQE8uzrvVkgYUFYmzk9WLZUw70M7uoWP38e81NmWXs'
  },
  algolia:{
    searchOnlyAPIKey: '2fb8c0fa1997cad95b65f984c6b294ce',
    appId: 'C4V2LOZR6L',
    indexName: 'post_marketplace_index'
  },
  fenixAllowedDeepLinkHosts: [
    'fenix.group'
  ],
  enableNotifications: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
