import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map, switchMap, first } from 'rxjs/operators';
import { OrgFirestore } from './org.service';
import { PostFirestore } from './post.service';
import { CONSTANTS } from '../constants';

export interface User {
  id?: string;
  name?: string;
  email?: string;
  org?: string[];
  preferences?: object;
  uid?: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
}


export interface UserFirestore {
  uid?: string;
  user_uuid: string;
  user_email: string;
  user_name: string;
  user_last_name: string;
  user_display_name: string;
  user_org: DocumentReference[];
  user_history: any;
  user_pinned?: any;
  user_purchased: object;
  user_preferences: any[];
  user_preference_notification: string;
  org?: OrgFirestore[];
  user_subscription_notification?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: Observable<UserFirestore>;
  public afsUserDoc: AngularFirestoreDocument<UserFirestore>;
  public afsUserCollection: AngularFirestoreCollection<UserFirestore>;
  private user_uid: string;

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {}

  private initUserOrg(uid){
    this.user_uid = uid;
    console.log("initUserOrg firebaseuser user_uid", uid);
    this.afsUserCollection = this.afs.collection(`user/${uid}/pwa_org`);
    return this.afsUserCollection.snapshotChanges().pipe(
      map(actions => {
        
        return actions.map(a => {
          const id = a.payload.doc.id;
          const data = a.payload.doc.data();
          console.log("initUserOrg", data);
          data.uid = this.user_uid;
          return data;
        });
      })
    );

    // return this.afsUser;
  }
  getUserPreferences(uid): Observable<UserFirestore> {
    console.log("getUserPreferences", uid);

    this.afsUserDoc = this.afs.doc<UserFirestore>(`user/${uid}`);
    return this.afsUserDoc.snapshotChanges().pipe(
      map(currentUser => {
        const user_data = currentUser.payload.data() as UserFirestore;
        console.log("initUser PREFS", user_data);
        // this.user = user_data;
        return user_data;
      })
    );
    // return this.afsUserPrefs;
  }
  getAuthenticatedUserOrg(): Observable<any> {

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(firebaseuser => {
        if (firebaseuser) {
          console.log("authState getAuthenticatedUser firebaseusr:",firebaseuser);
          return this.initUserOrg(firebaseuser.uid);
        } else {
          return of(null)
        }
      })
    );
    return this.user;
  }

  getUserOrg(user_org: DocumentReference) {
    // console.log("getUserOrg", user_org);
    return user_org.get().then((o) => {
      const id = o.id;
      const org_data = o.data() as OrgFirestore;
      org_data.id = id;
      console.log("getUserOrg org_data", org_data);
      return org_data;
		});
	}
  updateNotificationSetting(uid, value){
    console.log("updateNotificationSetting",uid, value);
    if(uid){
      this.afs.doc(`user/${uid}`).ref.update({ 'user_preference_notification': value});
      this.afs.doc(`user/${uid}`).ref.update({ 'user_preferences.pwa_user_preference_notifications': value });
    }
  }
  addBookmark(post_id, uid){
    console.log("addBookmark",uid, post_id);

    if(uid){
      const postCollection = this.afs.collection<PostFirestore>('post');
      const postref = postCollection.doc<PostFirestore>(post_id).ref;
      this.afs.doc(`user/${uid}`).ref.update({ 'user_pinned.marketplace_post_viewer': firebase.firestore.FieldValue.arrayUnion(postref)});
    }
  }
  removeBookmark(post_id, uid){
    console.log("removeBookmark",uid, post_id);

    if(uid){
      const postCollection = this.afs.collection<PostFirestore>('post');
      const postref = postCollection.doc<PostFirestore>(post_id).ref;
      this.afs.doc(`user/${uid}`).ref.update({ 'user_pinned.marketplace_post_viewer': firebase.firestore.FieldValue.arrayRemove(postref)});
    }
  }
  addHistory(post_id, uid){
    console.log("addHistory",uid, post_id);

    if(uid){
      const postCollection = this.afs.collection<PostFirestore>('post');
  		const postref = postCollection.doc<PostFirestore>(post_id).ref;
      this.afs.doc(`user/${uid}`).ref.update({ 'user_history.marketplace_post_viewer': firebase.firestore.FieldValue.arrayUnion(postref)});
    }
  }
  removeHistory(post_id, uid){
    console.log("removeHistory",uid, post_id);

    if(uid){
      const postCollection = this.afs.collection<PostFirestore>('post');
      const postref = postCollection.doc<PostFirestore>(post_id).ref;
      this.afs.doc(`user/${uid}`).ref.update({ 'user_history.marketplace_post_viewer': firebase.firestore.FieldValue.arrayRemove(postref)});
    }
  }

  /**
   *
   * Get a user by Id
   */
  getUser(id: string): Observable<UserFirestore> {

    console.log('Current User', id);

    const userRef = this.afs
      .doc<UserFirestore>('user/' + id);

    return userRef.snapshotChanges().pipe(map(currentUser => {
      return currentUser.payload.data();
    }));
  }

  updateUserTopicsPrefences(topics) {
    console.log(this.user_uid);
    console.log(topics);

    if (topics && topics.length ){
      const refArray = [];

      topics.forEach(tp => {
        const tpRef = this.afs
          .doc('topic/' + tp);
        refArray.push(tpRef.ref);
      });

      this.afs.doc(`user/${this.user_uid}`).ref
        .update({ 'user_preference_topics' : topics });
      this.afs.doc(`user/${this.user_uid}`).ref
            .update({ 'user_preferences.pwa_user_preference_topics': refArray });
    }else{
      this.afs.doc(`user/${this.user_uid}`).ref
        .update({ 'user_preference_topics': CONSTANTS.NOTOPICS });
      this.afs.doc(`user/${this.user_uid}`).ref
        .update({ 'user_preferences.pwa_user_preference_topics': CONSTANTS.NOTOPICS });
    }
  }

}
