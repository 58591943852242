import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserFirestore } from '../content/user.service';

export interface UserDevice {
    id: string;
    uuid: string;
    created: string;
    last_access: string;
    info: object;
    user: string;
}


export interface DeviceFirestore {
    device_id: string;
    device_created: string;
    device_last_access: string;
    device_info: object;
    device_user: string;
}


@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    public devices: Observable<UserDevice[]>;
    public deviceCollection: AngularFirestoreCollection<DeviceFirestore>;
    constructor(private firestoreDb: AngularFirestore) {

    }


    mappingFromFirestoreToPwaDevice(firestoreId: string, data: DeviceFirestore) {

        const dataTransformed: UserDevice = {
            id: firestoreId,
            uuid: data.device_id,
            created: data.device_created,
            last_access: data.device_last_access,
            info: data.device_info,
            user: data.device_user,
        };

        return dataTransformed;

    }

    addDevice(email, device: UserDevice) {

        const deviceToSend: DeviceFirestore = {
            device_id: device.uuid,
            device_created: device.created,
            device_last_access: device.last_access,
            device_info: device.info,
            device_user: email
        };

        return this.firestoreDb
            .collection('device').doc<DeviceFirestore>(device.id).get().subscribe((snapShot) => {
                //console.log(snapShot.exists);
                if (snapShot.exists) {
                    deviceToSend.device_created = snapShot.get('device_created');
                    return this.firestoreDb.collection<DeviceFirestore>('device').doc(device.id).update(deviceToSend);
                } else {
                    return this.firestoreDb.collection<DeviceFirestore>('device').doc(device.id).set(deviceToSend);
                }

            });

    }

    removeDevice(uuid) {

        return this.firestoreDb.collection('device').doc<DeviceFirestore>(uuid).delete();

    }

    getDevice(user: UserFirestore, id: string): Observable<UserDevice> {
        const deviceRef = this.firestoreDb.collection('device').doc<DeviceFirestore>(id);
        return deviceRef.snapshotChanges().pipe(map(currentDevice => {
            return this.mappingFromFirestoreToPwaDevice(currentDevice.payload.id, currentDevice.payload.data());
        }));
    }

    getDevices(email: string) {
        this.deviceCollection = this.firestoreDb.collection<DeviceFirestore>('device');

        return this.deviceCollection.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data() as DeviceFirestore;
                return this.mappingFromFirestoreToPwaDevice(a.payload.doc.id, data);
            }))
        );
    }

}
