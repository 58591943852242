// import * as auth0 from '@auth0/auth0-spa-js';
import createAuth0Client from '@auth0/auth0-spa-js';
import { AUTH_CONFIG } from './auth.config';

let _auth0Client = null;
let _idToken = null;
let _profile = null;

class Auth0Client {

  constructor() {
    //_isLoggedIn = this.isAuthenticated();
    // _auth0Client = new auth0.WebAuth(AUTH_CONFIG);
    createAuth0Client({client_id: AUTH_CONFIG.client_id, domain: AUTH_CONFIG.domain}).then(auth0 =>{
      _auth0Client = auth0;
    });
  }

  getIdToken() {
    console.log("In Auth0 getIdToken");
    console.log(_idToken);
    return _idToken;
  }

  getProfile() {
    return _profile;
  }
  async handleSpaCallback(){
    const cb = await _auth0Client.handleRedirectCallback();
    return cb;
  }
  handleCallback() {
    console.log("handleCallback", window.location.hash);
    return new Promise((resolve, reject) => {
      _auth0Client.parseHash(async (err, authResult) => {
        window.location.hash = '';
        if (err) return reject(err);

        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
        }else{
          // not an authentication request
          return resolve(false);
        }

        return resolve(true);
      });
    });
  }
  renewTokens() {
    console.log("RENEW TOKENS");
    return new Promise((resolve, reject) => {
      _auth0Client.checkSession({}, (err, authResult) => {
        if (err) {
          console.log("checksession error", err);
          this.handleCallback();
          reject (err);
        }
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          return resolve(true);
        } else {
          return resolve(false);
        }
      });
    });
  }
  passwordlessStart(email){
    return new Promise((resolve, reject) => {
      _auth0Client.passwordlessStart({
  		  connection: 'email',
  		  send: 'code',
  		  email: email
  		}, function (err, res) {

        if (err) return reject(err);

        console.log("passwordlessStart res", res);
        return resolve(true);
  		});
    });
  }
  passwordlessLogin(email, code){
    return new Promise((resolve, reject) => {
      _auth0Client.passwordlessLogin({
        connection: 'email',
        email: email,
        verificationCode: code
      }, function (err, res) {
      // handle errors or continue
        console.log('error', err);
        console.log('passwordlessLogin auth Response', res);
        //set SESSION

        if (err) return reject(err);

        console.log("passwordlessStart res", res);
        return resolve(true);
      });
    });
  }
  // async checkSession(){
  //   const token = _auth0Client.getTokenSilently();

  // }
  loginWithRedirect(redirect=true): Promise<any>{

    if (_auth0Client){
      return _auth0Client.getTokenSilently().then( (tokenSilent) => { 
          console.log('tokenSilent', tokenSilent);
          console.log(tokenSilent);
          if (tokenSilent) {
            return _auth0Client.getUser().then(user => {
              console.log("USER", user);
              this.setSession(user);
            });
          } 

      }).catch((err) => {
        if(!redirect){
          return Promise.resolve(false);
        }
        
        return _auth0Client.loginWithRedirect({ redirect_uri: AUTH_CONFIG.redirectUri }).then(token => {

          return _auth0Client.getUser().then(user => {
            console.log("USER", user);
            this.setSession(user);
            
          });

        }); 
      });
    }


    
    // _auth0Client.loginWithRedirect({
    //   redirect_uri: AUTH_CONFIG.redirect_uri
    // });
  }
  async getUser(){
    const user = await _auth0Client.getUser();
    console.log("getUSER", user);
    return user ;
  }

  signIn(email, code) {

    const pwdlresponse = this.passwordlessLogin(email, code);
    console.log("In Auth0 signIn");
    // if(pwdlresponse){
    //   _auth0Client.authorize({
    //     connection: 'email'
    //     // email: email,
    //     // verificationCode: code
    //   });
    // }
    // if(this.isAuthenticated()){
    //   this.renewTokens();
    // }else{
    //   _auth0Client.authorize({
    //     connection: 'email'
    //     // email: email,
    //     // verificationCode: code
    //   });
    //   //will redirect to https://auth0.com/docs/hosted-pages/login to allow for things like social login and OKTA SSO
    //   //will call handleCallback
    // }

  }

  signOut() {
    // _idToken = null;
    // _profile = null;
    // //_isLoggedIn = false;
    // // Remove tokens and expiry time from localStorage
    // localStorage.removeItem('access_token');
    // localStorage.removeItem('id_token');
    // localStorage.removeItem('expires_at');
    //localStorage.removeItem('profile');
    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
    _auth0Client.logout();
  }

  setSession(user) {
    // _idToken = authResult.idToken;
    // _profile = authResult.idTokenPayload;
    // // Set the time that the Access Token will expire at
    // const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    // localStorage.setItem('access_token', authResult.accessToken);
    // localStorage.setItem('id_token', authResult.idToken);
    // //localStorage.setItem('profile', authResult.idTokenPayload);
    // localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('user', JSON.stringify(user));

    console.log("SETSESSION");
  }
  isAuthenticated(){
    // Check whether the current time is past the
    // Access Token's expiry time
    const _isLoggedIn = localStorage.getItem('isLoggedIn') || false;
    console.log("localStorage.getItem('isLoggedIn')", _isLoggedIn);
    if( _isLoggedIn === 'true'){
      const user = JSON.parse(localStorage.getItem('user'));
      return user;
    }else{
      return false;
    }
    // const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    // return new Date().getTime() < expiresAt;
  }

}

export const auth0Client = new Auth0Client();
