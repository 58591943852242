import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// Import Auth0Cordova
import Auth0Cordova from '@auth0/cordova';
import { AuthService } from './services/auth.service';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireRemoteConfig, SETTINGS, Parameter } from '@angular/fire/remote-config';

// Auth Config
import { AUTH_CONFIG } from './services/auth.config';

// DeepLinks
import { FenixDeepLinks } from './services/content/deeplinks.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private remoteConfig: AngularFireRemoteConfig,
    public afAuth: AngularFireAuth,
    public deeplinks: FenixDeepLinks
  ) {

    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle('dark', shouldAdd);
    }
    this.initializeApp();
  }

  initializeApp() {
      this.platform.ready().then(() => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();

        // Redirect back to app after authenticating
        (window as any).handleOpenURL = (url: string) => {
          // To be handle by auth0 cordova
          if (url.indexOf(AUTH_CONFIG.domain) !== -1 ) {
            Auth0Cordova.onRedirectUri(url);
          } else {
            // To be handle by fenix deep links
            this.deeplinks.openDeepLink(url);
          }
        };

      });
      // FOR WEB Auth0
      this.authService.handleAuthentication();

      //Remote config
      console.log('REMOTE CONFIG : ', SETTINGS.toString());
      this.remoteConfig.fetchAndActivate().then((val) => {
        this.remoteConfig.getAll().then( value => {
          console.log('SUBSCRIBE REMOTE CONFIG', value.AUTH_CONFIG.asString());
          this.authService.initAuthRemote(value.AUTH_CONFIG.asString());
        });
      });
      this.remoteConfig.changes.subscribe( (value: Parameter) => {
        console.log('SUBSCRIBE REMOTE CONFIG');
        this.authService.initAuthRemote(value.asString());
      });

  }
}
